<template>
  <section>
    <b-card> Gz Kitap && Panel</b-card>
    <b-card>
      <div class="row match-height ">
        <div class="col-sm-6 col-md-4 col-xl-2 ">
          <div class="card text-center bg-info bg-light-5">
            <div class="card-header ">
              <span class="mt-0 font-weight-bolder"> Bugün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.today">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.today.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.today.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-info bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Dün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.yesterday">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.yesterday.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.yesterday.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Son 7 Gün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.seven_day">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.seven_day.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.seven_day.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Geçen Son 7 Gün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.last_seven_day">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.last_seven_day.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.last_seven_day.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-success bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Bu Ay </span>
            </div>
            <div class="card-body" v-if="_orderDetail.month">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.month.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.month.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-success bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Geçen Ay </span>
            </div>
            <div class="card-body" v-if="_orderDetail.last_month">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.last_month.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.last_month.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-gradient-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Bu Yıl </span>
            </div>
            <div class="card-body" v-if="_orderDetail.year">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.year.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.year.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-gradient-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Geçen Yıl </span>
            </div>
            <div class="card-body" v-if="_orderDetail.last_year">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.last_year.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.last_year.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-gradient-success bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Son 1 Yıl </span>
            </div>
            <div class="card-body" v-if="_orderDetail.one_year">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.one_year.tr.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.one_year.tr.sum) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-gradient-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Bu Yıl </span>
            </div>
            <div class="card-body" v-if="_orderDetail.year">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.year.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.year.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-gradient-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Geçen Yıl </span>
            </div>
            <div class="card-body" v-if="_orderDetail.last_year">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.last_year.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.last_year.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-gradient-success bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Son 1 Yıl </span>
            </div>
            <div class="card-body" v-if="_orderDetail.one_year">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.one_year.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.one_year.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-xl-2 ">
          <div class="card text-center bg-info bg-light-5">
            <div class="card-header ">
              <span class="mt-0 font-weight-bolder"> Bugün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.today">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.today.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.today.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-info bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Dün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.yesterday">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.yesterday.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.yesterday.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Son 7 Gün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.seven_day">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.seven_day.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.seven_day.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-warning bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Geçen Son 7 Gün </span>
            </div>
            <div class="card-body" v-if="_orderDetail.last_seven_day">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.last_seven_day.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{
                    priceFormart(_orderDetail.last_seven_day.diger.sum, 'en-EN', 'EUR')
                  }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-success bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Bu Ay </span>
            </div>
            <div class="card-body" v-if="_orderDetail.month">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.month.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.month.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-2">
          <div class="card text-center bg-success bg-light-5">
            <div class="card-header">
              <span class="mt-0 font-weight-bolder"> Geçen Ay </span>
            </div>
            <div class="card-body" v-if="_orderDetail.last_month">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder"> {{ _orderDetail.last_month.diger.count }} Adet </h2>
                <h3 v-if="user.role=='admin'">{{ priceFormart(_orderDetail.last_month.diger.sum, 'en-EN', 'EUR') }}</h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    </b-card>
    <b-card v-if="false">
            <pre>
      <b-row>
        <b-col md="6">
          {{ option }}
        </b-col>
        <b-col md="6">
          {{ _orderOnemonth }}
        </b-col>
      </b-row>
      </pre>

      <app-echart-stacked-area
          :option-data="option"
      />
    </b-card>

  </section>
</template>

<script>
import {mapGetters} from "vuex";
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  name: "Home",
  components: {AppEchartStackedArea},
  data: () => ({
    user: JSON.parse(localStorage.getItem('userData')),
    option: {
      xAxisData: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12'],
      series: [
        {
          name: 'Point One',
          type: 'line',
          stack: 'Total',
          data: [220, 332, 281, 334, 290, 430, 310, 350, 510, 550, 720, 650, 760, 850],
        },
        {
          name: 'Point Two',
          type: 'line',
          stack: 'Total',
          data: [220, 282, 191, 534, 290, 430, 350, 300, 580, 463, 380, 600, 560, 840],
        },
        {
          name: 'Point Three',
          type: 'line',
          stack: 'Total',
          data: [750, 232, 601, 154, 390, 330, 410, 510, 420, 320, 580, 690, 650, 800],
        },
      ],
    }
  }),
  computed: {
    ...mapGetters('home', ["_orderDetail", "_orderOnemonth"])
  },
  methods: {
    priceFormart(price, format = 'tr-Tr', format2 = 'TRY') {
      var formattedOutput = new Intl.NumberFormat(format, {
        style: 'currency',
        currency: format2,
        minimumFractionDigits: 2,
      });
      return formattedOutput.format(price);
    }
  },
  created() {
    this.$store.dispatch('home/getOrderDetail');
    this.$store.dispatch('home/getOrderOnemonth');
    this.$store.dispatch('question/getAll2')
    this.$store.dispatch('orderQuestion/getAll')
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
